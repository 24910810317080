<template>
  <validation-observer ref="refFormObserver" #default="{ passes }">
    <b-modal
      id="group-basic-information-modal"
      :title="t('group.modal.basic_info.modal_title')"
      :ok-title="t('button.save')"
      :cancel-title="t('button.cancel')"
      cancel-variant="outline-secondary"
      size="lg"
      @ok.prevent="passes(submitFormData)"
      @cancel="resetForm"
      @show="fillDataIfProvided"
      no-close-on-backdrop
    >
      <b-form>
        <b-row>
          <b-col cols="12" class="mb-2">
            <br>
            <h1 class="section-label mx-0 mt-0">{{ t('group.modal.basic_info.modal_common_section') }}</h1>
          </b-col>
          <b-col md="6">
            <required-text-input-field
              v-model="formData.name"
              name="Group name"
              :label="t('group.basic_info.name')"
              label-for="group-name"
              placeholder="Escapade à Paris"
            />
          </b-col>
          <b-col md="6">
            <required-select-box
              v-model="formData.type"
              :available-options="groupCreationConfig.type"
              name="Group type"
              :label="t('group.basic_info.type')"
              label-for="group-type"
              :placeholder="t('group.basic_info.group_type_place_holder')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <required-select-box
              v-model="formData.difficulty"
              :available-options="groupCreationConfig.groupDifficulty"
              name="Group difficulty"
              :label="t('group.basic_info.difficulty')"
              label-for="group-difficulty"
              :placeholder="t('group.basic_info.difficulty_place_holder')"
            />
          </b-col>
          
          <b-col md="6">
            <b-form-group
              :label="t('group.basic_info.deposit')"
              label-for="accommodation-deposit-amount"
            >
          <b-input-group
                prepend="$"
                append="CAD"
              >
                <b-form-input placeholder="100" v-model="formData.depositAmount"/>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="t('group.basic_info.departure_date')"
              label-for="departure-date"
            >
                <b-form-input
                  id="departure-date"
                  class="mb-1"
                  type="date"
                  v-model="formData.departureDate"
                  :name="t('group.basic_info.departure_date')"
                  locale="fr"
                  max="2100-01-01"
                  placeholder="Aucune date sélectionnée"
                />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="t('group.basic_info.return_date')"
              label-for="return-date"
            >
                <b-form-input
                  id="return-date"
                  class="mb-1"
                  type="date"
                  v-model="formData.returnDate"
                  :name="t('group.basic_info.return_date')"
                  locale="fr"
                  max="2100-01-01"
                  placeholder="Aucune date sélectionnée"
                />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="t('group.basic_info.guide_presence')" label-for="v-c-password">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-form-checkbox name="checkbox-input" v-model="hasGuide"/>
                </b-input-group-prepend>
                <b-form-input placeholder="Aucun" :disabled="!hasGuide" v-model="formData.guide"/>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col v-if="pcvoyagesEnabled" md="6">
            <b-form-group label="Groupe de passagers - PC Voyages" label-for="pc_voyages_passenger_group">
              <b-form-input
                  id="pc_voyages_passenger_group"
                  class="mb-1"
                  type="text"
                  v-model="formData.pcVoyagesPassengerGroup"
                  name="pc_voyages_passenger_group"
                  locale="fr"
                />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mb-2">
            <h1 class="section-label mx-0 mt-2">
              {{ t('group.modal.basic_info.modal_reservation_section') }}
            </h1>
          </b-col>

          <b-col md="6">
            <b-form-group
              :label="t('group.basic_info.payment_limit')"
              label-for="paiment-limit-date"
            >
                <b-form-input
                  id="paiment-limit-date"
                  class="mb-1"
                  type="date"
                  v-model="formData.paymentLimit"
                  :name="t('group.basic_info.payment_limit')"
                  locale="fr"
                  max="2100-01-01"
                  placeholder="Aucune date sélectionnée"
                />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="t('group.basic_info.booking_limit')"
              label-for="booking_limit"
            >
                <b-form-input
                  id="booking_limit"
                  class="mb-1"
                  type="date"
                  v-model="formData.bookingLimit"
                  :name="t('group.basic_info.booking_limit')"
                  locale="fr"
                  max="2100-01-01"
                  placeholder="Aucune date sélectionnée"
                />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  // VBModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import groupCreationConfig from '@core/data/groupCreationConfig.js';
import RequiredTextInputField from "@/shared/components/RequiredTextInputField";

import {required} from '@validations';
import {reactive, ref} from "@vue/composition-api";
import {ValidationObserver} from "vee-validate";
import {useValidatorsWithAutoFormDataCleanUp} from "@/shared/composables/use-validators";
import {useGroupApi} from "@/modules/group/composables/use-group-api";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import RequiredSelectBox from "@/shared/components/RequiredSelectBox";
import {useLocalisation} from "@/shared/composables/use-localisation";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {AUTH_STORE_NAMESPACE} from "@/modules/authnz/models/store";

export default {
  name: 'group-basic-information-modal',
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    RequiredTextInputField,
    RequiredSelectBox,
    ValidationObserver,
  },
  directives: {
    // 'b-modal': VBModal,
    Ripple,
  },
  props: {
    groupId: {
      type: String,
      required: false,
      default: 'NOT_PROVIDED_BY_PARENT_COMPONENT',
    },
    information: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    action: {
      type: String,
      required: true,
    }
  },
  setup(props, {emit}) {
    const {createGroupWithBasicInformation, updateGroupBasicInformation} = useGroupApi();
    const {t} = useLocalisation();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();
    const {store} = useApplicationContext();

    let formData = reactive({});
    const hasGuide = ref(false);

    const fillDataIfProvided = () => {
      if (props.action === 'update') {
        Object.assign(formData, props.information);
        hasGuide.value = props.information?.guide !== undefined && props.information?.guide !== '';
      }
    };

    let pcvoyagesEnabled = ref(false);
    pcvoyagesEnabled.value = store.state[AUTH_STORE_NAMESPACE].orgSettings.pcvoyages_enable;

    const submitFormData = async () => {
      try {
        let savedGroupId;

        switch (props.action) {
          case 'create':
            savedGroupId = await createGroupWithBasicInformation(formData);
            break;
          case 'update':
            savedGroupId = await updateGroupBasicInformation(props.groupId, formData);
            break;
          default:
            displayErrorMessage(`Provided action (${props.action}) is invalid or unsupported`);
        }

        displaySuccessMessage('Les informations de base du groupe ont été sauvegardées avec succès');
  

        emit('monica-group-saved', savedGroupId);
      } catch {
        displayErrorMessage("La sauvegarde d'informations du groupe a échoué");
      }
    };

    const {refFormObserver, resetForm} = useValidatorsWithAutoFormDataCleanUp(formData);

    return {
      required,
      formData,
      hasGuide,
      groupCreationConfig,
      pcvoyagesEnabled,
      refFormObserver,
      resetForm,
      t,
      submitFormData,
      fillDataIfProvided,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
